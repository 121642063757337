import React, {useEffect, useState} from "react";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";

import '../style.scss';

import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import iconModel from "../../../../../assets/icons/model.svg";
import iconWindow from "../../../../../assets/icons/window.svg";
import iconPeriod from "../../../../../assets/icons/period.svg";
import iconTarget from "../../../../../assets/icons/target-icon.svg";
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";
import {
    formatDate,
    isNumber,
    parseCustomFilter,
    parseFilter,
    QueueServer,
    sendSdk,
    stringifyFilter
} from "../../../../../utils";
import cx from "classnames";
import ShortInfo from "../components/ShortInfo";
import IconFilter from "../../../../../assets/icons/icon-filter-blue.svg";
import IconFilterWhite from "../../../../../assets/icons/icon-filter-white.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-white.svg'
import {Btn} from "../../../../../ui";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";
import {
    clearFilter, dashboardsSettings, setFilter as setDispatchFilter, setSetting, setSettingEventTarget,
    setSettingModel,
    setSettingPeriod,
    setSettingScale,
    setSettingWindow
} from "../../../../../redux/slices/dashboards";
import {getEvents} from "../../../../../actions/events";
import {eventsData, ICurrentEvents} from "../../../../../redux/slices/events";
import DashboardsChart from "../components/Charts";
import DashboardsTable from "../components/Table";
import {trans} from "../../../../../_locales";
import {onChangeFilterQuery, onLoadingSelectDate} from "../functions";
import {Groups, Models, Windows} from "../../../../../data";
import {getSelectChangesTitle, onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";
import {getEventTitles} from "../../Reports/functions";
import {IFilterMediaArray} from "../../../../../components/Tables/interfaces";

const CampaignEfficiency = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const reduxEvents = useAppSelector(eventsData);
    const language = useAppSelector(currentUserLang);
    const settings = useAppSelector(dashboardsSettings);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;

    const [firstLoading, setFirstLoading] = useState(true);

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'campaign_efficiency'}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        const tempBreadcrumbs:any = [
            {
              href: '/projects/',
              title: 'Проект',
            }
        ]
        dispatch(setBreadcrumbs(tempBreadcrumbs));
        dispatch(clearInstructions());
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'home'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));

        if(!(settings && settings.categorize)) {
            if(projectId == '777') {
                setCategorize('day');
            } else setCategorize('day');
        }
    }, [dispatch, projectId, permissions]);

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const defaultPeriodStart = searchParams.get("ps") || '';
    const defaultPeriodEnd = searchParams.get("pe") || '';
    const defaultModel = searchParams.get("m") || '';
    const defaultWindow = searchParams.get("w") || '';
    const defaultScale = searchParams.get("s") || '';
    const defaultEventTargetActive = searchParams.get("et") || '';
    const defaultFilter = searchParams.get("f") || '';
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [model, setModel] = useState('mtd');
    const [attribution_window, setWindow] = useState(30);
    const [categorize, setCategorize] = useState('day');
    const [eventTarget, setEventTarget] = useState('');
    const [selectDate, setSelectDate] = useState<string>(trans('Select the period', language));

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period,
                    model: settings.model ? settings.model : parsed.model,
                    window: settings.window ? settings.window : parsed.window,
                    categorize: settings.categorize ? settings.categorize : parsed.categorize,
                    events: settings.events ? settings.events : parsed.events
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [settings])

    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
        dispatch(setSettingPeriod({
            start: response.start,
            end: response.end
        }))
    }
    const datasetModel = Models(language);
    const datasetWindow = Windows(language);
    const datasetCategorize = Groups(language);

    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {'all_target_events': trans('All event targets', language)})
    const [filterEventTarget, setFilterEventTarget] = useState<Array<ICurrentEvents>>( [])
    const [eventTargetActive, setEventTargetActive] = useState<Array<string>>( [])
    const onChangeEventTarget = (response:object) => {
        const {title, listActive} = getSelectChangesTitle(response, trans('All event targets', language));
        setEventTarget(title);
        setEventTargetActive(listActive)
    };
    useEffect(()=>{
        if(reduxEvents && Array.isArray(reduxEvents)) {
            const result:Array<ICurrentEvents> = [];
            reduxEvents.map((item:any)=> {
                const temp: ICurrentEvents = {} as ICurrentEvents;
                if(eventTargetActive.includes(item.event_target_name)) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                    result.push(temp);
                }
            })
            dispatch(setSettingEventTarget(result));
            setFilterEventTarget(result);
        }
    }, [reduxEvents, eventTarget, eventTargetActive])
    useEffect(() => {
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && Array.isArray(parseSettings.events)) {
                    const {title, keys} = getEventTitles(parseSettings.events);
                    setEventTarget(title);
                    setEventTargetActive(keys);
                    setFilterEventTarget(parseSettings.events)
                }
            }
        });
    }, [dispatch, language, projectId]);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    if(defaultModel) {
                        setModel(defaultModel)
                    } else if(parseSettings && parseSettings.model) {
                        setModel(parseSettings.model)
                    } else setModel(r.payload.attribution_model);
                    if(defaultWindow && isNumber(defaultWindow) && ['15', '30', '60', '90'].includes(defaultWindow)) {
                        setWindow(+defaultWindow)
                    } else if(parseSettings && parseSettings.window) {
                        setWindow(parseSettings.window)
                    } else setWindow(r.payload.attribution_window);
                    if(defaultScale) {
                        setCategorize(defaultScale)
                    } else if(parseSettings && parseSettings.categorize) {
                        setCategorize(parseSettings.categorize)
                    } else setCategorize('day');
                    if(defaultPeriodStart && defaultPeriodEnd) {
                        setPeriodStart(defaultPeriodStart);
                        setPeriodEnd(defaultPeriodEnd);
                        setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                    } else if (parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else
                        onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                }
            })
            if(defaultFilter) {
                const temp:IFilterMediaArray = parseFilter(defaultFilter, '^*^');
                onSubmitFilter(temp);
                dispatch(setDispatchFilter(temp));
            }
            if(defaultEventTargetActive) {
                setEventTarget(defaultEventTargetActive);
                setEventTargetActive(defaultEventTargetActive.split(','))
            }
        }
    }, [projectId, reduxUser.id])

    // ------Filter--------
    const [filter, setFilter] = useState<IFilterMediaArray>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:IFilterMediaArray) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'campaign_efficiency',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    const queue = new QueueServer();

    //Hide header when scroll
    const [lastScroll, setLastScroll] = useState(0);
    const defaultOffset = 100;
    const containHide = (elem:HTMLElement) => elem.classList.contains('hide');

    const handleScroll = (event:any) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.target;
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        if(body && header && content && scrollTop > lastScroll && !containHide(header) && scrollTop > defaultOffset && (scrollTop < scrollHeight - offsetHeight - 200)) {
            header.classList.add('hide');
            content.classList.add('stretch');
            body.classList.add('no-scroll');
        } else if(body && header && content && scrollTop < lastScroll && containHide(header)) {
            header.classList.remove('hide');
            content.classList.remove('stretch');
            body.classList.remove('no-scroll');
        }
        setLastScroll(scrollTop);
    }

    const onChangeQuery = ({
        periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: string,
        attr_window?: number,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray
    }) => {
        const query = onChangeFilterQuery({periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter});
        const temp = {
            search: query
        }
        navigate(temp);
    };

    useEffect(()=> {
        if(!firstLoading) {
            onChangeQuery({
                periodStart,
                periodEnd,
                model,
                attr_window: attribution_window,
                scale: categorize,
                eventTargetActive,
                filter
            });
        }
    }, [periodStart, periodEnd, model, attribution_window, categorize, eventTargetActive, filter])

  return (
    <div className={contentClassName} onScroll={()=>null}>
        <div className="dashboard-page-filter">
            <div className="dashboard-page-filter__date">
              <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
            </div>
            <div className="dashboard-page-filter__select">
              <Select
                  selectActive={(datasetModel as any)[model]}
                  icon={iconModel}
                  dataset={datasetModel}
                  onChangeActive={(res:any) => onChangeGeneralSelect(res, (value:any) => {
                      setModel(value);
                      dispatch(setSettingModel(value))
                  })}
              />
            </div>
            <div className="dashboard-page-filter__select">
              <Select selectActive={`${trans('Attribution window', language)}: ${attribution_window} ${trans('days', language)}`} icon={iconWindow} dataset={datasetWindow} onChangeActive={(res:any)=> {
                  onChangeGeneralSelect(res, (value:any) => {
                      const result = Number(value);
                      if(result === 15 || result === 30 || result === 60 || result === 90) {
                          setWindow(result);
                          dispatch(setSettingWindow(result));
                      }
                  });
              }} />
            </div>
            <div className="dashboard-page-filter__select">
              <Select
                  selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                  icon={iconPeriod}
                  dataset={datasetCategorize}
                  onChangeActive={(res:any)=> onChangeGeneralSelect(res, (value:any) => {
                      setCategorize(value);
                      dispatch(setSettingScale(value))
                  })} />
            </div>
            <div className="dashboard-page-filter__select">
              <Select selectActive={(datasetEventTarget as any)[eventTarget] ? (datasetEventTarget as any)[eventTarget] : trans('All event targets', language)} icon={iconTarget} dataset={datasetEventTarget} onChangeActive={onChangeEventTarget} />
            </div>
            <div className="dashboard-page-filter__auto">
              <button className={`dashboard-page-filter__btn ${filterActive?'active':''}`} onClick={()=>{
                  projectId && dispatch(openModal({
                      name: ModalName.FILTER,
                      data: {
                        onSubmit: onSubmitFilter,
                        projectId: +projectId
                      }
                  }))
              }}>
                  <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                  {trans('Filter', language)}
              </button>
              { filterActive && <Btn
                  className={'dashboard-page-filter__reset'}
                  isIconBtn={true}
                  size='sm'
                  color='transparent'
                  onClick={onResetFilter}
                  icon={{
                    Component: CloseIcon,
                    width: 16,
                    height: 16
                  }}
              /> }
            </div>
        </div>
        <div className="dashboard-page-summary">
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_cost'}
                    titleDefault={trans('media_index_cost', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_impression'}
                    titleDefault={trans('media_index_impression', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_reach'}
                    titleDefault={trans('media_index_reach', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_frequency'}
                    titleDefault={trans('media_index_frequency', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_click'}
                    titleDefault={trans('media_index_click', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_lead'}
                    titleDefault={trans('media_index_lead', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    model={model}
                    window={attribution_window}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_consumed_lead'}
                    titleDefault={trans('media_index_consumed_lead', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={attribution_window}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item" >
                <ShortInfo
                    name={'media_index_cpm'}
                    titleDefault={trans('media_index_cpm', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item" >
                <ShortInfo
                    name={'media_index_cpr'}
                    titleDefault={trans('media_index_cpr', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_ctr'}
                    titleDefault={trans('media_index_ctr', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_cpc'}
                    titleDefault={trans('media_index_cpc', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'media_index_cpa'}
                    titleDefault={trans('media_index_cpa', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    model={model}
                    window={attribution_window}
                    filter={filter}
                    targetEvents={filterEventTarget[0]}
                    queue={queue}
                />
            </div>

        </div>
        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_lead_cpa'}
                        titleDefault={trans('media_chart_lead_cpa', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarLinear'}
                        categorize={categorize}
                        model={model}
                        window={attribution_window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_impression'}
                        titleDefault={trans('media_chart_impression', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarStack'}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        dataIndexes={[2]}
                        nameIndex={1}
                        labelIndex={0}
                        queue={queue}
                    />
                </div>
            </div>
        </div>
        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_cost'}
                        titleDefault={trans('media_chart_cost', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarStack'}
                        categorize={categorize}
                        model={model}
                        window={attribution_window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        dataIndexes={[2]}
                        nameIndex={1}
                        queue={queue}
                        isShort={true}
                    />
                </div>
            </div>
            {/*<div className="dashboard-page-block__item">*/}
            {/*    <div className="dashboard-page__diagram">*/}
            {/*        <DashboardsChart*/}
            {/*            name={'media_chart_cr_impression_click'}*/}
            {/*            titleDefault={trans('media_chart_cr_impression_click', language)}*/}
            {/*            projectId={Number(projectId)}*/}
            {/*            periodStart={periodStart}*/}
            {/*            periodEnd={periodEnd}*/}
            {/*            type={'Line'}*/}
            {/*            categorize={categorize}*/}
            {/*            filter={filter}*/}
            {/*            targetEvents={filterEventTarget}*/}
            {/*            queue={queue}*/}
            {/*            isShort={true}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_clicks_and_ctr'}
                        titleDefault={trans('media_chart_clicks_and_ctr', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'BarStackLinear'}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        dataIndexes={[2]}
                        nameIndex={1}
                        queue={queue}
                        isShort={true}
                    />
                </div>
            </div>
        </div>
        {/*<div className="dashboard-page-table">*/}
        {/*    <div className="dashboard-page__table">*/}
        {/*        <DashboardsTable*/}
        {/*            name={'media_table_adv_cost_plan_fact'}*/}
        {/*            projectId={Number(projectId)}*/}
        {/*            periodStart={periodStart}*/}
        {/*            periodEnd={periodEnd}*/}
        {/*            selectDate={selectDate}*/}
        {/*            categorize={categorize}*/}
        {/*            model={model}*/}
        {/*            window={window}*/}
        {/*            filter={filter}*/}
        {/*            targetEvents={filterEventTarget}*/}
        {/*            queue={queue}*/}
        {/*            hideTotal={true}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'media_table_full_stat'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    selectDate={selectDate}
                    categorize={categorize}
                    model={model}
                    window={attribution_window}
                    filter={filter}
                    targetEvents={filterEventTarget}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
        <div className="dashboard-page-block" >
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_adv_cohort_efficiency'}
                        titleDefault={trans('media_chart_adv_cohort_efficiency', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'LineMulti'}
                        model={model}
                        window={attribution_window}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        labelIndex={1}
                        nameIndex={0}
                        dataIndexes={[2]}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'media_table_reach_crossing'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        selectDate={selectDate}
                        filter={filter}
                        targetEvents={filterEventTarget}
                        categorize={categorize}
                        queue={queue}
                        tabs={{
                            'media_table_reach_crossing': trans('By campaign', language),
                            'media_table_reach_crossing_domain': trans('By domain', language),
                            'media_table_reach_crossing_source': trans('By source', language),
                            'media_table_reach_crossing_ssp': trans('By ssp', language)
                        }}
                        hideTotal={true}
                    />
                </div>
            </div>
        </div>
        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_distr_impression_user'}
                        titleDefault={trans('media_chart_distr_impression_user', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={attribution_window}
                        type={'BarLinear'}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'media_chart_flight_duration'}
                        titleDefault={trans('media_chart_flight_duration', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'LineMulti'}
                        model={model}
                        window={attribution_window}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        nameIndex={1}
                        queue={queue}
                    />
                </div>
            </div>
        </div>
        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'media_table_domain'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    selectDate={selectDate}
                    model={model}
                    window={attribution_window}
                    filter={filter}
                    targetEvents={filterEventTarget}
                    categorize={categorize}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'media_table_media_to_target_event'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        selectDate={selectDate}
                        model={model}
                        window={attribution_window}
                        filter={filter}
                        targetEvents={filterEventTarget}
                        categorize={categorize}
                        queue={queue}
                        hideTotal={true}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'media_table_not_see_adv'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        selectDate={selectDate}
                        categorize={categorize}
                        model={model}
                        window={attribution_window}
                        filter={filter}
                        targetEvents={filterEventTarget}
                        queue={queue}
                        hideTotal={true}
                        disableName={true}
                        posTooltip={'left'}
                        tabs={{
                            'media_table_not_see_adv': trans('media_table_not_see_adv', language),
                            'media_table_conversion_lift_media_camp_on_source': trans('media_table_conversion_lift_media_camp_on_source', language),
                            'media_table_conversion_lift_media_on_source': trans('media_table_conversion_lift_media_on_source', language),
                            'media_table_conversion_lift_media_on_utm_camp': trans('media_table_conversion_lift_media_on_utm_camp', language)
                        }}
                    />
                </div>
            </div>
        </div>
        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'media_table_video_action'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    selectDate={selectDate}
                    filter={filter}
                    targetEvents={filterEventTarget}
                    categorize={categorize}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>
        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'media_table_viewability'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    selectDate={selectDate}
                    model={model}
                    window={attribution_window}
                    filter={filter}
                    categorize={categorize}
                    queue={queue}
                    targetEvents={filterEventTarget}
                    hideTotal={true}
                />
            </div>
        </div>
    </div>
  )
}

export default CampaignEfficiency;