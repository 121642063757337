import {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../redux/slices/user";

import Loudspeaker from '../../../../assets/icons/loudspeaker.svg';
import Cart from '../../../../assets/icons/cart-icon.svg';
import User from '../../../../assets/icons/user_circle.svg';
import Comparison from '../../../../assets/icons/comparison.svg';
import Target from '../../../../assets/icons/target.svg';
import Raise from '../../../../assets/icons/raise.svg';

import './style.scss';
import {trans} from "../../../../_locales";
import {sendSdk} from "../../../../utils";
import {clearInstructions} from "../../../../redux/slices/instructions";
import {getPages} from "../../../../components/SideMenu/Role";

const Dashboards = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const language = useAppSelector(currentUserLang);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');
    const reduxUserId = useAppSelector(userId);
    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'dashboards'
                }
            })
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        dispatch(setBreadcrumbs([
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `#`,
              title: trans('Project reporting', language)
            }
          ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboards'
        })))
        dispatch(clearInstructions());
    }, [dispatch, projectId, permissions]);

    useEffect(() => {
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        header && header.classList.remove('hide');
        content && content.classList.remove('stretch');
        body && body.classList.remove('no-scroll');
    }, []);

    return(
        <div className={'dashboards'}>
            <div className="dashboards__content">
                { isViewGeneral ?
                    <Link to={`/projects/${projectId}/reports/general`} className="dashboards__item">
                        <div className="dashboards__icon">
                            <img src={Loudspeaker} alt=""/>
                        </div>
                        <div className="dashboards__name">{trans('General indicators', language)}</div>
                    </Link> :
                    <div className="dashboards__item dashboards__not-found">
                        <div className="dashboards__icon">
                            <img src={Loudspeaker} alt=""/>
                        </div>
                        <div className="dashboards__name">({trans('General indicators', language)})</div>
                    </div>
                }
                { isViewEcom ?
                    <Link to={`/projects/${projectId}/reports/ecommerce`} className="dashboards__item">
                        <div className="dashboards__icon">
                            <img src={Cart} alt=""/>
                        </div>
                        <div className="dashboards__name">{trans('E-Commerce Reports', language)}</div>
                    </Link> :
                    <div className="dashboards__item dashboards__not-found">
                        <div className="dashboards__icon">
                            <img src={Cart} alt=""/>
                        </div>
                        <div className="dashboards__name">({trans('E-Commerce Reports', language)})</div>
                    </div>
                }

                { projectId && (+projectId === 12475 || isViewUserBehavior) ?
                    <Link to={`/projects/${projectId}/reports/user_behavior`} className="dashboards__item">
                        <div className="dashboards__icon">
                            <img src={User} alt=""/>
                        </div>
                        <div className="dashboards__name">{trans('User behavior analysis', language)}</div>
                    </Link> :
                    <div className="dashboards__item dashboards__not-found">
                        <div className="dashboards__icon">
                            <img src={User} alt=""/>
                        </div>
                        <div className="dashboards__name">({trans('Soon', language)})</div>
                    </div>
                }
                { projectId && (+projectId === 12484 || +projectId===12483) ?
                    <Link to={`/projects/${projectId}/reports/getintent`} className="dashboards__item">
                        <div className="dashboards__icon">
                            <img src={Comparison} alt=""/>
                        </div>
                        <div className="dashboards__name">GetIntent</div>
                    </Link> : projectId && (+projectId === 12523) ?
                    <Link to={`/projects/${projectId}/reports/postview_sessions`} className="dashboards__item">
                        <div className="dashboards__icon">
                            <img src={Comparison} alt=""/>
                        </div>
                        <div className="dashboards__name">PostView Sessions</div>
                    </Link> :
                    <div className="dashboards__item dashboards__not-found">
                        <div className="dashboards__icon">
                            <img src={Comparison} alt=""/>
                        </div>
                        <div className="dashboards__name">({trans('Soon', language)})</div>
                    </div>
                }
                <div className="dashboards__item dashboards__not-found">
                    <div className="dashboards__icon">
                        <img src={Target} alt=""/>
                    </div>
                    <div className="dashboards__name">({trans('Soon', language)})</div>
                </div>
                <div className="dashboards__item dashboards__not-found">
                    <div className="dashboards__icon">
                        <img src={Raise} alt=""/>
                    </div>
                    <div className="dashboards__name">({trans('Soon', language)})</div>
                </div>
                {/*<div className="dashboards__item dashboards__not-found">*/}
                {/*    <div className="dashboards__icon">*/}
                {/*        <img src={List} alt=""/>*/}
                {/*    </div>*/}
                {/*    <div className="dashboards__name">План факт<br/> перформанс каналов<br/> (Скоро)</div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Dashboards;